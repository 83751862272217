export default [
  {
    header: 'Manager',
  },
  {
    title: 'Regions',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'List',
        route: 'apps-region-list',
      },
      {
        title: 'Add',
        route: { name: 'apps-region-add' },
      },
    ],
  },
  {
    title: 'Clusters',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'List',
        route: 'apps-cluster-list',
      },
      {
        title: 'Add',
        route: { name: 'apps-cluster-add' },
      },
    ],
  },

  {
    title: 'WP Server',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'List',
        route: 'apps-server-list',
      },
      {
        title: 'Add',
        route: { name: 'apps-server-add' },
      },
    ],
  },
  {
    title: 'WP Region',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'List',
        route: 'apps-wp-region-list',
      },
      {
        title: 'Add',
        route: { name: 'apps-wp-region-add' },
      },
    ],
  },
  {
    title: 'WP Size',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'List',
        route: 'apps-size-list',
      },
      {
        title: 'Add',
        route: { name: 'apps-size-add' },
      },
    ],
  },
  {
    title: 'WP Cluster',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'List',
        route: 'apps-wp-cluster-list',
      },
      {
        title: 'Add',
        route: { name: 'apps-wp-cluster-add' },
      },
    ],
  },
  {
    title: 'Plan',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'List',
        route: 'apps-plan-list',
      },
      {
        title: 'Add',
        route: { name: 'apps-plan-add' },
      },
    ],
  },
  {
    title: 'Cluster Plan',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'List',
        route: 'apps-cluster-plan-list',
      },
      {
        title: 'Add',
        route: { name: 'apps-cluster-plan-add' },
      },
    ],
  },
]
